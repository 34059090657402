<template>
  <div class="career">
    <div style="background: no-repeat center center / cover" :style="{ backgroundImage: 'url(' + require('@/assets/breadcumb.jpg') + ')' }" class="breadcumb-area">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h2 class="breadcumb-title">Kariyer </h2>
            <ul class="breadcumb-menu">
              <li>
                <router-link to="/">Anasayfa</router-link>
              </li>
              <li>Kariyer</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="book-appoinment pb--160">
      <div class="container">
        <div class="appoinment-inner appoinment-s2">
          <div class="row">
            <div class="col-lg-6 offset-lg-6 col-md-8 offset-md-4">
              <div class="appnt-form">
                <h4>Bizimle çalışmak ister misiniz?</h4>
                <form id="appointment">
                  <div class="form-group">
                    <select id="doctor" class="form-control" name="doctor">
                      <option disabled selected value="">Açık Pozisyonlar</option>
                      <option value="rashed">Gıda Mühendisi</option>
                      <option value="tanvir">Teknik Ressam</option>
                      <option value="saikat_ahmed">Genel Muhasebe Çalışanı</option>
                      <option value="soykat_chowdhory">Fabrika Çalışanı</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <input class="form-control" name="name" placeholder="Ad Soyad" type="text">
                  </div>
                  <div class="form-group">
                    <input class="form-control" name="email" placeholder="E-Posta Adresi" type="email">
                  </div>
                  <div class="form-group">
                    <input class="form-control" name="date" placeholder="Doğum Tarihi* gg/aa/yy" type="text">
                  </div>
                  <div class="form-group">
                    <textarea id="msg" class="form-control" name="message" placeholder="Deneyimler - Mesaj"></textarea>
                  </div>
                  <div class="form-group">
                    <button class="btn btn-primary btn-block text-uppercase" type="submit">
                      Gönder
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <img alt="vector-shape2" class="vector-shape2" src="images/icon/vector-shape2.png">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  beforeRouteLeave (to, from, next) {
    next();
    window.scrollTo(0, 0);
  },
  beforeRouteEnter (to, from, next) {
    next();
    window.scrollTo(0, 0);
  }
}
</script>
